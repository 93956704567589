@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  font-family: "Montserrat" !important;
}

.inner-container {
  padding: 0 20px;
}

.table-item {
  text-transform: capitalize;
}

.index-action-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  flex-wrap: wrap;
  row-gap: 20px;
}

.index-action-container {
  margin: 10px 0;
}

.category-bar {
  display: flex;
  width: 100%;
  height: 30px;
  border-radius: 15px;
}

.category-item {
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mirue {
  background-color: yellow;
  color: #333333;
}
.shopping {
  background-color: red;
  color: white;
}
.food {
  background-color: brown;
  color: white;
}
.house {
  background-color: aquamarine;
  color: #333333;
}
.traffic {
  background-color: aqua;
  color: #333333;
}
.mobile {
  background-color: blue;
  color: white;
}
.personal {
  background-color: violet;
  color: #333333;
}
.culture {
  background-color: green;
  color: #333333;
}
.hospital {
  background-color: gray;
  color: #333333;
}
.gift {
  background-color: skyblue;
  color: #333333;
}
.beauty {
  background-color: blanchedalmond;
  color: #333333;
}
.etc {
  background-color: black;
  color: white;
}

.category-description-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  padding-top: 20px;
  column-gap: 10px;
}

.category-description-wrap > div {
  min-width: 50px;
  max-width: 80px;
  width: 100%;
}
